import { zGetUserTopicsTrpcInput } from '@calories/admin-backend/src/router/users/getUserTopics/input'
import { canViewUsers } from '@calories/admin-backend/src/utils/can'
import InfiniteScroll from 'react-infinite-scroller'
import { useDebounce } from 'usehooks-ts'
import { Alert } from '../../../components/Alert'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { layoutContentElRef } from '../../../components/Layout'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

export const TopicsPage = withPageWrapper({
  checkAccess: ({ ctx }) => canViewUsers(ctx.me),
  showLoaderOnFetching: false,
  title: () => `Topics`,
})(() => {
  const { formik } = useForm({
    initialValues: { search: '', topicId: '' },
    validationSchema: zGetUserTopicsTrpcInput.pick({ search: true, topicId: true }),
  })
  const search = useDebounce(formik.values.search, 500)
  const topicId = useDebounce(formik.values.topicId, 500)
  const { data, error, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage, isRefetching } =
    trpc.getUserTopics.useInfiniteQuery(
      {
        search,
        topicId,
      },
      {
        getNextPageParam: (lastPage) => {
          return lastPage.nextCursor
        },
      }
    )

  return (
    <Segment title={'Topics'} helpId="topics">
      <div className={css.filter}>
        <FormItems>
          <Input maxWidth={'100%'} label="Search" name="search" formik={formik} />
          <Input maxWidth={'100%'} label="Topic ID" name="topicId" formik={formik} />
        </FormItems>
      </div>
      {isLoading || isRefetching ? (
        <Loader type="section" />
      ) : isError ? (
        <Alert color="red">{error.message}</Alert>
      ) : !data.pages[0].topics.length ? (
        <Alert color="brown">Nothing found by search</Alert>
      ) : (
        <div className={css.topics}>
          <InfiniteScroll
            threshold={250}
            loadMore={() => {
              if (!isFetchingNextPage && hasNextPage) {
                void fetchNextPage()
              }
            }}
            hasMore={hasNextPage}
            loader={
              <div className={css.more} key="loader">
                <Loader type="section" />
              </div>
            }
            getScrollParent={() => layoutContentElRef.current}
            useWindow={(layoutContentElRef.current && getComputedStyle(layoutContentElRef.current).overflow) !== 'auto'}
          >
            {data.pages
              .flatMap((page) => page.topics)
              .map((topic) => (
                <div className={css.topic} key={topic.id}>
                  <Segment
                    helpId={null}
                    size={2}
                    title={
                      <a className={css.topicLink} href={topic.debugUrl} target="_blank">
                        {topic.description}
                      </a>
                    }
                  >
                    {topic.imageUrl && <img className={css.pic} src={topic.imageUrl} alt={topic.description} />}
                    Created At: {topic.createdAt.toISOString()}
                  </Segment>
                </div>
              ))}
          </InfiniteScroll>
        </div>
      )}
    </Segment>
  )
})
