import { canAnalyzePushes } from '@calories/admin-backend/src/utils/can'
import { Segment } from '../../../components/Segment'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getPushCampaignRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

export const PushCampaignPage = withPageWrapper({
  useQuery: () => {
    const { pushCampaignSerialNumber } = getPushCampaignRoute.useParams()
    return trpc.getPushCampaign.useQuery({
      serialNumber: +pushCampaignSerialNumber,
    })
  },
  setProps: ({ queryResult }) => ({
    pushCampaign: queryResult.data.pushCampaign,
  }),
  checkAccess: ({ ctx }) => canAnalyzePushes(ctx.me),
  showLoaderOnFetching: false,
  title: ({ pushCampaign }) => `Push Campaign: ${pushCampaign.serialNumber}`,
})(({ pushCampaign }) => (
  <Segment title={`Push Campaign: ${pushCampaign.serialNumber}`} helpId="push-campaign-view">
    <Segment helpId={null}>
      <pre className={css.pushCampaignInfo}>{JSON.stringify(pushCampaign, null, 2)}</pre>
    </Segment>
  </Segment>
))
