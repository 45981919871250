import { canViewUsers } from '@calories/admin-backend/src/utils/can'
import InfiniteScroll from 'react-infinite-scroller'
import { Link } from 'react-router-dom'
import { Alert } from '../../../components/Alert'
import { layoutContentElRef } from '../../../components/Layout'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getUserImagesRoute, getUserRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

export const UserImagesPage = withPageWrapper({
  useQuery: () => {
    const { userSerialNumber } = getUserImagesRoute.useParams()
    return trpc.getUser.useQuery({
      serialNumber: +userSerialNumber,
    })
  },
  setProps: ({ queryResult }) => ({
    user: queryResult.data.user,
  }),
  checkAccess: ({ ctx }) => canViewUsers(ctx.me),
  showLoaderOnFetching: false,
  title: ({ user }) => `User Images: ${user.serialNumber}`,
})(({ user }) => {
  const { data, error, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage, isRefetching } =
    trpc.getUserImages.useInfiniteQuery(
      {
        userId: user.id,
      },
      {
        getNextPageParam: (lastPage) => {
          return lastPage.nextCursor
        },
      }
    )

  return (
    <Segment
      helpId="user-images"
      title={
        <>
          <Link to={getUserRoute({ userSerialNumber: user.serialNumber.toString() })}>User {user.serialNumber}</Link>{' '}
          Images
        </>
      }
    >
      {isLoading || isRefetching ? (
        <Loader type="section" />
      ) : isError ? (
        <Alert color="red">{error.message}</Alert>
      ) : !data.pages[0].images.length ? (
        <Alert color="brown">Nothing found by search</Alert>
      ) : (
        <div className={css.images}>
          <InfiniteScroll
            threshold={250}
            loadMore={() => {
              if (!isFetchingNextPage && hasNextPage) {
                void fetchNextPage()
              }
            }}
            className={css.tbody}
            hasMore={hasNextPage}
            loader={
              <tr>
                <td className={css.more} key="loader">
                  <Loader type="section" />
                </td>
              </tr>
            }
            getScrollParent={() => layoutContentElRef.current}
            useWindow={(layoutContentElRef.current && getComputedStyle(layoutContentElRef.current).overflow) !== 'auto'}
          >
            {data.pages
              .flatMap((page) => page.images)
              .map((image) => (
                <div className={css.item}>
                  <pre className={css.data}>{JSON.stringify(image, null, 2)}</pre>
                  <div className={css.image}>
                    <img src={image.url512} width="512" height="512" alt="" />
                  </div>
                </div>
              ))}
          </InfiniteScroll>
        </div>
      )}
    </Segment>
  )
})
