import { Help } from '../Help'
import css from './index.module.scss'

export const Segment = ({
  title,
  size = 1,
  description,
  children,
  helpId,
}: {
  title?: React.ReactNode
  size?: 1 | 2 | 3
  description?: string
  children?: React.ReactNode
  helpId?: string | null
}) => {
  const titleElement = !title ? null : size === 1 ? (
    <h1 className={css.title}>{title}</h1>
  ) : size === 2 ? (
    <h2 className={css.title}>{title}</h2>
  ) : (
    <h3 className={css.title}>{title}</h3>
  )
  return (
    <div className={css.segment}>
      {titleElement}
      {description && <p className={css.description}>{description}</p>}
      {helpId && <Help id={helpId} className={css.help} />}
      {children && <div className={css.content}>{children}</div>}
    </div>
  )
}
