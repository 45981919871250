import { zResetPasswordTrpcInput } from '@calories/admin-backend/src/router/auth/resetPassword/input'
import { zPasswordsMustBeTheSame, zStringRequired } from '@calories/shared/src/zod'
import Cookies from 'js-cookie'
import { useSearchParams } from 'react-router-dom'
import { Alert } from '../../../components/Alert'
import { Button, Buttons, LinkButton } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { Segment } from '../../../components/Segment'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getSignInRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'

export const ResetPasswordPage = withPageWrapper({
  redirectAuthorized: true,
  title: 'Reset Password',
})(() => {
  const [searchParams] = useSearchParams()
  const resetPasswordTokenRaw = searchParams.get('resetPasswordToken')
  const trpcUtils = trpc.useContext()
  const resetPassword = trpc.resetPassword.useMutation()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      resetPasswordToken: resetPasswordTokenRaw || '',
      password: '',
      passwordAgain: '',
    },
    validationSchema: zResetPasswordTrpcInput
      .extend({
        passwordAgain: zStringRequired,
      })
      .superRefine(zPasswordsMustBeTheSame('password', 'passwordAgain')),
    onSubmit: async ({ password, resetPasswordToken }) => {
      const { token } = await resetPassword.mutateAsync({ password, resetPasswordToken })
      Cookies.set('token', token, { expires: 99999 })
      void trpcUtils.invalidate()
    },
    successMessage: false,
  })

  return (
    <Segment title="Reset Password" helpId="reset-password">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input label="Password" name="password" type="password" formik={formik} />
          <Input label="Password Again" name="passwordAgain" type="password" formik={formik} />
          <Alert {...alertProps} />
          <Buttons>
            <Button {...buttonProps}>Reset Password</Button>
            <LinkButton color="neutral" to={getSignInRoute()}>
              Sing In
            </LinkButton>
          </Buttons>
        </FormItems>
      </form>
    </Segment>
  )
})
