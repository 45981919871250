import { zCreateDocTrpcInput } from '@calories/admin-backend/src/router/docs/createDoc/input'
import { canManageDocs } from '@calories/admin-backend/src/utils/can'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert } from '../../../components/Alert'
import { Button } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { RichEditor } from '../../../components/RichEditor'
import { Segment } from '../../../components/Segment'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getDocsRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'

export const DocNewPage = withPageWrapper({
  checkAccess: ({ ctx }) => canManageDocs(ctx.me),
  title: 'New News Post',
})(() => {
  const navigate = useNavigate()
  const createDoc = trpc.createDoc.useMutation()
  const nowRef = useRef(new Date())
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      publishedAt: nowRef.current,
      title: '',
      bodyMd: '',
      urlSuffix: '',
    },
    validationSchema: zCreateDocTrpcInput,
    onSubmit: async (values) => {
      await createDoc.mutateAsync(values)
      navigate(getDocsRoute())
    },
    resetOnSuccess: true,
    enableReinitialize: true,
    successMessage: 'Doc created successfully',
  })
  return (
    <Segment title="New Doc" helpId="doc-new">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input label="Published At" name="publishedAt" type="datetime-local" formik={formik} />
          <Input label="Title" name="title" formik={formik} />
          <Input label="Url Suffix" name="urlSuffix" formik={formik} />
          <RichEditor label="Body" name="bodyMd" formik={formik} />
          <Alert {...alertProps} />
          <Button {...buttonProps}>Create Doc</Button>
        </FormItems>
      </form>
    </Segment>
  )
})
