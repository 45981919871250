import { canManageDocs } from '@calories/admin-backend/src/utils/can'
import InfiniteScroll from 'react-infinite-scroller'
import { Link } from 'react-router-dom'
import { Alert } from '../../../components/Alert'
import { layoutContentElRef } from '../../../components/Layout'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getEditDocRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

export const DocsPage = withPageWrapper({
  title: 'Docs',
  checkAccess: ({ ctx }) => canManageDocs(ctx.me),
})(() => {
  const { data, error, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage, isRefetching } =
    trpc.getDocs.useInfiniteQuery(
      {},
      {
        getNextPageParam: (lastPage) => {
          return lastPage.nextCursor
        },
      }
    )

  return (
    <Segment title="Docs" helpId="docs">
      {isLoading || isRefetching ? (
        <Loader type="section" />
      ) : isError ? (
        <Alert color="red">{error.message}</Alert>
      ) : !data.pages[0].docs.length ? (
        <Alert color="brown">Nothing found</Alert>
      ) : (
        <div className={css.docs}>
          <InfiniteScroll
            threshold={250}
            loadMore={() => {
              if (!isFetchingNextPage && hasNextPage) {
                void fetchNextPage()
              }
            }}
            hasMore={hasNextPage}
            loader={
              <div className={css.more} key="loader">
                <Loader type="section" />
              </div>
            }
            getScrollParent={() => layoutContentElRef.current}
            useWindow={(layoutContentElRef.current && getComputedStyle(layoutContentElRef.current).overflow) !== 'auto'}
          >
            {data.pages
              .flatMap((page) => page.docs)
              .map((doc) => (
                <div className={css.doc} key={doc.serialNumber}>
                  <Segment
                    helpId={null}
                    size={2}
                    title={
                      <Link className={css.docLink} to={getEditDocRoute({ docId: doc.id })}>
                        {doc.title}
                      </Link>
                    }
                  >
                    Publish Date: {doc.publishedAt.toISOString()}
                    <br />
                    Public Url:{' '}
                    <a href={doc.url} target="_blank">
                      {doc.url}
                    </a>
                  </Segment>
                </div>
              ))}
          </InfiniteScroll>
        </div>
      )}
    </Segment>
  )
})
