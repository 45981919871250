import * as cans from '@calories/admin-backend/src/utils/can'
import { createRef } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { useMe } from '../../lib/ctx'
import { env } from '../../lib/env'
import {
  getAdminActionLogsRoute,
  getAdminsRoute,
  getAiImageProvidersConfigRoute,
  getAnalyticCampaignsRoute,
  getAnalyticRequestsRoute,
  getAppConfigRoute,
  getAutoCampaignsRoute,
  getAutoEmailCampaignsRoute,
  getClearAdaptyRoute,
  getClearUsersRoute,
  getConversionPostbackConfigsRoute,
  getConversionPostbackLogsRoute,
  getConversionsListRoute,
  getConversionsPredictionsRoute,
  getConversionsStatsRoute,
  getConversionsStatsWebRoute,
  getDocsRoute,
  getEditAutoCampaignsRoute,
  getEditProfileRoute,
  getHomeRoute,
  getNewAdminRoute,
  getNewAnalyticCampaignRoute,
  getNewDocRoute,
  getNewNewsPostRoute,
  getNewPushCampaignRoute,
  getNewUpgradePostRoute,
  getNewsPostsRoute,
  getPushCampaignsRoute,
  getRetentionRoute,
  getSignInRoute,
  getSignOutRoute,
  getTicketsRoute,
  getTopicsRoute,
  getUnsubscriptionsRoute,
  getUpgradesPostsRoute,
  getUsersRoute,
} from '../../lib/routes'
import css from './index.module.scss'

export const layoutContentElRef = createRef<HTMLDivElement>()

export const Layout = () => {
  const me = useMe()

  return (
    <div className={css.layout}>
      <div className={css.navigation}>
        <div className={css.logo}>Calories Admin</div>
        <ul className={css.menu}>
          {me ? (
            <>
              <li className={css.item}>
                <Link className={css.link} to={getHomeRoute()}>
                  Home
                </Link>
              </li>
              {cans.canViewUsers(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getUsersRoute()}>
                    Users
                  </Link>
                </li>
              )}
              {cans.canViewUsers(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getTopicsRoute()}>
                    Topics
                  </Link>
                </li>
              )}
              {cans.canManageAdmins(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getAdminsRoute()}>
                    Admins
                  </Link>{' '}
                  <Link className={css.link} to={getNewAdminRoute()}>
                    (New)
                  </Link>
                </li>
              )}
              {cans.canViewUnsubscriptions(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getUnsubscriptionsRoute()}>
                    Unsubscriptions
                  </Link>
                </li>
              )}
              {(cans.canAnalyzePushes(me) || cans.canSendPushes(me)) && (
                <li className={css.item}>
                  {cans.canAnalyzePushes(me) && (
                    <Link className={css.link} to={getPushCampaignsRoute()}>
                      Push Campaigns
                    </Link>
                  )}{' '}
                  {cans.canSendPushes(me) && (
                    <Link className={css.link} to={getNewPushCampaignRoute()}>
                      (New)
                    </Link>
                  )}
                </li>
              )}
              {(cans.canAnalyzePushes(me) || cans.canSendPushes(me)) && (
                <li className={css.item}>
                  {cans.canAnalyzePushes(me) && (
                    <Link className={css.link} to={getAutoCampaignsRoute()}>
                      Auto Campaigns
                    </Link>
                  )}{' '}
                  {cans.canSendPushes(me) && (
                    <Link className={css.link} to={getEditAutoCampaignsRoute()}>
                      (Edit)
                    </Link>
                  )}
                </li>
              )}
              {cans.canAnalyzeEmails(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getAutoEmailCampaignsRoute()}>
                    Auto Email Campaigns
                  </Link>
                </li>
              )}
              {cans.canAnalyzeTopics(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getAnalyticRequestsRoute()}>
                    Analytic Requests
                  </Link>
                </li>
              )}
              {cans.canAnalyzeTopics(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getAnalyticCampaignsRoute()}>
                    Analytic Campaigns
                  </Link>{' '}
                  <Link className={css.link} to={getNewAnalyticCampaignRoute()}>
                    (New)
                  </Link>
                </li>
              )}
              {cans.canManageUpgradesPosts(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getUpgradesPostsRoute()}>
                    Upgrades Posts
                  </Link>{' '}
                  <Link className={css.link} to={getNewUpgradePostRoute()}>
                    (New)
                  </Link>
                </li>
              )}
              {cans.canManageNewsPosts(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getNewsPostsRoute()}>
                    News Posts
                  </Link>{' '}
                  <Link className={css.link} to={getNewNewsPostRoute()}>
                    (New)
                  </Link>
                </li>
              )}
              {cans.canManageDocs(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getDocsRoute()}>
                    Docs
                  </Link>{' '}
                  <Link className={css.link} to={getNewDocRoute()}>
                    (New)
                  </Link>
                </li>
              )}
              {cans.canManageAppConfig(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getAppConfigRoute()}>
                    App Config
                  </Link>
                </li>
              )}
              {cans.canManageAppConfig(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getAiImageProvidersConfigRoute()}>
                    Ai Image Providers
                  </Link>
                </li>
              )}
              {cans.canManageAppConfig(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getConversionPostbackConfigsRoute()}>
                    Postback Configs
                  </Link>
                </li>
              )}
              {cans.canManageAppConfig(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getConversionPostbackLogsRoute()}>
                    Postback Logs
                  </Link>
                </li>
              )}
              {cans.canViewConversions(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getConversionsListRoute()}>
                    Conversions List
                  </Link>
                </li>
              )}
              {cans.canViewConversions(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getConversionsPredictionsRoute()}>
                    Conversions Predictions
                  </Link>
                </li>
              )}
              {cans.canViewConversions(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getConversionsStatsRoute()}>
                    Conversions Stats
                  </Link>
                </li>
              )}
              {cans.canViewConversions(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getConversionsStatsWebRoute()}>
                    Conversions Stats Web
                  </Link>
                </li>
              )}
              {cans.canViewConversions(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getRetentionRoute()}>
                    Retention
                  </Link>
                </li>
              )}
              {env.HOST_ENV !== 'production' && cans.canClearAdapty(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getClearAdaptyRoute()}>
                    Adapty Cleaning
                  </Link>
                </li>
              )}
              {env.HOST_ENV !== 'production' && cans.canManageUsers(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getClearUsersRoute()}>
                    Users Cleaning
                  </Link>
                </li>
              )}
              {cans.canViewTickets(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getTicketsRoute()}>
                    Tickets
                  </Link>
                </li>
              )}
              {cans.canViewAdminActionLogs(me) && (
                <li className={css.item}>
                  <Link className={css.link} to={getAdminActionLogsRoute()}>
                    Admin Action Logs
                  </Link>
                </li>
              )}
              <li className={css.item}>
                <Link className={css.link} to={getEditProfileRoute()}>
                  Edit Profile
                </Link>
              </li>
              <li className={css.item}>
                <Link className={css.link} to={getSignOutRoute()}>
                  Log Out ({me.email})
                </Link>
              </li>
            </>
          ) : (
            <>
              <li className={css.item}>
                <Link className={css.link} to={getSignInRoute()}>
                  Sign In
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
      <div className={css.content} ref={layoutContentElRef}>
        <Outlet />
      </div>
    </div>
  )
}
