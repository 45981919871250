import { zUpdateHelpTrpcInput } from '@calories/admin-backend/src/router/help/updateHelp/input'
import { canManageHelp } from '@calories/admin-backend/src/utils/can'
import cn from 'classnames'
import { useState } from 'react'
import { useMe } from '../../lib/ctx'
import { useForm } from '../../lib/form'
import { trpc } from '../../lib/trpc'
import { Alert } from '../Alert'
import { Button, Buttons } from '../Button'
import { FormItems } from '../FormItems'
import { RichEditor } from '../RichEditor'
import css from './index.module.scss'

export const Help = ({ id, className }: { id: string; className?: string }) => {
  const qr = trpc.getHelp.useQuery({
    id,
  })
  const noHelpText = 'There is no help for this section yet'
  const bodyHtml = qr.data?.help.bodyHtml || noHelpText
  const helpTextExists = bodyHtml !== noHelpText
  const me = useMe()
  const [state, setState] = useState<'hide' | 'show' | 'edit'>('hide')
  const trpcUtil = trpc.useUtils()

  const updateHelp = trpc.updateHelp.useMutation()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      id,
      bodyMd: qr.data?.help.bodyMd || '',
    },
    validationSchema: zUpdateHelpTrpcInput,
    onSubmit: async (values) => {
      await updateHelp.mutateAsync({
        id: values.id,
        bodyMd: values.bodyMd,
      })
      await trpcUtil.getHelp.refetch({ id })
      setState('show')
    },
    resetOnSuccess: false,
    enableReinitialize: true,
    // successMessage: 'Help updated successfully',
    successMessage: false,
  })

  return (
    <div className={cn(css.help, className)}>
      <div className={css.links}>
        {state === 'hide' && (
          // {state === 'hide' && (canManageHelp(me) || helpTextExists) && (
          <a
            href="#"
            className={css.dashedLink}
            onClick={(e) => {
              e.preventDefault()
              setState('show')
            }}
          >
            {helpTextExists ? 'Show Help' : 'No Help'}
          </a>
        )}
        {state === 'show' && (
          <a
            href="#"
            className={css.dashedLink}
            onClick={(e) => {
              e.preventDefault()
              setState('hide')
            }}
          >
            Hide Help
          </a>
        )}
        {state === 'show' && canManageHelp(me) && qr.data && (
          <a
            href="#"
            className={css.dashedLink}
            onClick={(e) => {
              e.preventDefault()
              setState('edit')
            }}
          >
            Edit Help
          </a>
        )}
      </div>
      {state === 'show' && <div className={css.helpContent} dangerouslySetInnerHTML={{ __html: bodyHtml }} />}
      {state === 'edit' && (
        <form onSubmit={formik.handleSubmit}>
          <FormItems>
            <RichEditor label="Help Content" name="bodyMd" formik={formik} />
            <Alert {...alertProps} />
            <Buttons>
              <Button {...buttonProps}>Update Help</Button>
              <Button
                color="red"
                type="button"
                onClick={() => {
                  setState('show')
                }}
              >
                Cancel
              </Button>
            </Buttons>
          </FormItems>
        </form>
      )}
    </div>
  )
}
