import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Layout } from './components/Layout'
import { AppContextProvider } from './lib/ctx'
import * as routes from './lib/routes'
import { SentryUser } from './lib/sentry'
import { TrpcProvider } from './lib/trpc'
import { AdminEditPage } from './pages/admins/AdminEditPage'
import { AdminNewPage } from './pages/admins/AdminNewPage'
import { AdminsPage } from './pages/admins/AdminsPage'
import { AnalyticCampaignNewPage } from './pages/analyticCampaigns/AnalyticCampaignNewPage'
import { AnalyticCampaignPage } from './pages/analyticCampaigns/AnalyticCampaignPage'
import { AnalyticCampaignsPage } from './pages/analyticCampaigns/AnalyticCampaignsPage'
import { AnalyticRequestsPage } from './pages/analyticRequests/AnalyticRequestsPage'
import { EditProfilePage } from './pages/auth/EditProfilePage'
import { RequestResetPasswordPage } from './pages/auth/RequestResetPasswordPage'
import { ResetPasswordPage } from './pages/auth/ResetPasswordPage'
import { SignInPage } from './pages/auth/SignInPage'
import { SignOutPage } from './pages/auth/SignOutPage'
import { AutoCampaignsEditPage } from './pages/autoCampaigns/AutoCampaignsEditPage'
import { AutoCampaignsPage } from './pages/autoCampaigns/AutoCampaignsPage'
import { AutoEmailCampaignsPage } from './pages/autoEmailCampaigns/AutoEmailCampaignsPage'
import { ConversionsListPage } from './pages/conversions/ConversionsListPage'
import { ConversionsPredictionsPage } from './pages/conversions/ConversionsPredictionsPage'
import { ConversionsStatsPage } from './pages/conversions/ConversionsStatsPage'
import { ConversionsStatsWebPage } from './pages/conversions/ConversionsStatsWebPage'
import { PosbackLogsListPage } from './pages/conversions/PosbackLogsListPage'
import { RetentionPage } from './pages/conversions/RetentionPage'
import { DocEditPage } from './pages/docs/DocEditPage'
import { DocNewPage } from './pages/docs/DocNewPage'
import { DocsPage } from './pages/docs/DocsPage'
import { NewsPostEditPage } from './pages/newsPosts/NewsPostEditPage'
import { NewsPostNewPage } from './pages/newsPosts/NewsPostNewPage'
import { NewsPostsPage } from './pages/newsPosts/NewsPostsPage'
import { AdminActionLogsPage } from './pages/other/AdminActionLogsPage'
import { AiImageProvidersConfigPage } from './pages/other/AiImageProvidersConfigPage'
import { AppConfigPage } from './pages/other/AppConfigPage'
import { ClearAdaptyPage } from './pages/other/ClearAdaptyPage'
import { ConversionPostbackConfigsPage } from './pages/other/ConversionPostbackConfigsPage'
import { HomePage } from './pages/other/HomePage'
import { NotFoundPage } from './pages/other/NotFoundPage'
import { TopicsPage } from './pages/other/TopicsPage'
import { PushCampaignNewPage } from './pages/pushCampaigns/PushCampaignNewPage'
import { PushCampaignPage } from './pages/pushCampaigns/PushCampaignPage'
import { PushCampaignsPage } from './pages/pushCampaigns/PushCampaignsPage'
import { TicketPage } from './pages/tickets/TicketPage'
import { TicketsPage } from './pages/tickets/TicketsPage'
import { UnsubscriptionPage } from './pages/unsubscriptions/UnsubscriptionPage'
import { UnsubscriptionsPage } from './pages/unsubscriptions/UnsubscriptionsPage'
import { UpgradePostEditPage } from './pages/upgradesPosts/UpgradePostEditPage'
import { UpgradePostNewPage } from './pages/upgradesPosts/UpgradePostNewPage'
import { UpgradesPostsPage } from './pages/upgradesPosts/UpgradesPostsPage'
import { ClearUsersPage } from './pages/users/ClearUsersPage'
import { UserImagesPage } from './pages/users/UserImagesPage'
import { UserPage } from './pages/users/UserPage'
import { UserTopicsPage } from './pages/users/UserTopicsPage'
import { UsersPage } from './pages/users/UsersPage'
import './styles/global.scss'

export const App = () => {
  return (
    <HelmetProvider>
      <TrpcProvider>
        <AppContextProvider>
          <BrowserRouter>
            <SentryUser />
            <Routes>
              <Route path={routes.getSignOutRoute.definition} element={<SignOutPage />} />
              <Route element={<Layout />}>
                <Route path={routes.getHomeRoute.definition} element={<HomePage />} />
                <Route path={routes.getSignInRoute.definition} element={<SignInPage />} />
                <Route path={routes.getRequestResetPasswordRoute.definition} element={<RequestResetPasswordPage />} />
                <Route path={routes.getResetPasswordRoute.definition} element={<ResetPasswordPage />} />
                <Route path={routes.getEditProfileRoute.definition} element={<EditProfilePage />} />
                <Route path={routes.getUsersRoute.definition} element={<UsersPage />} />
                <Route path={routes.getUserRoute.definition} element={<UserPage />} />
                <Route path={routes.getUserImagesRoute.definition} element={<UserImagesPage />} />
                <Route path={routes.getTopicsRoute.definition} element={<TopicsPage />} />
                <Route path={routes.getUserTopicsRoute.definition} element={<UserTopicsPage />} />
                <Route path={routes.getUnsubscriptionsRoute.definition} element={<UnsubscriptionsPage />} />
                <Route path={routes.getUnsubscriptionRoute.definition} element={<UnsubscriptionPage />} />
                <Route path={routes.getTicketsRoute.definition} element={<TicketsPage />} />
                <Route path={routes.getTicketRoute.definition} element={<TicketPage />} />
                <Route path={routes.getPushCampaignsRoute.definition} element={<PushCampaignsPage />} />
                <Route path={routes.getPushCampaignRoute.definition} element={<PushCampaignPage />} />
                <Route path={routes.getAutoEmailCampaignsRoute.definition} element={<AutoEmailCampaignsPage />} />
                <Route path={routes.getAutoCampaignsRoute.definition} element={<AutoCampaignsPage />} />
                <Route path={routes.getEditAutoCampaignsRoute.definition} element={<AutoCampaignsEditPage />} />
                <Route path={routes.getNewPushCampaignRoute.definition} element={<PushCampaignNewPage />} />
                <Route path={routes.getAnalyticRequestsRoute.definition} element={<AnalyticRequestsPage />} />
                <Route path={routes.getAnalyticCampaignsRoute.definition} element={<AnalyticCampaignsPage />} />
                <Route path={routes.getAnalyticCampaignRoute.definition} element={<AnalyticCampaignPage />} />
                <Route path={routes.getNewAnalyticCampaignRoute.definition} element={<AnalyticCampaignNewPage />} />
                <Route path={routes.getUpgradesPostsRoute.definition} element={<UpgradesPostsPage />} />
                <Route path={routes.getEditUpgradePostRoute.definition} element={<UpgradePostEditPage />} />
                <Route path={routes.getNewUpgradePostRoute.definition} element={<UpgradePostNewPage />} />
                <Route path={routes.getNewsPostsRoute.definition} element={<NewsPostsPage />} />
                <Route path={routes.getEditNewsPostRoute.definition} element={<NewsPostEditPage />} />
                <Route path={routes.getNewNewsPostRoute.definition} element={<NewsPostNewPage />} />
                <Route path={routes.getDocsRoute.definition} element={<DocsPage />} />
                <Route path={routes.getEditDocRoute.definition} element={<DocEditPage />} />
                <Route path={routes.getNewDocRoute.definition} element={<DocNewPage />} />
                <Route path={routes.getAdminsRoute.definition} element={<AdminsPage />} />
                <Route path={routes.getEditAdminRoute.definition} element={<AdminEditPage />} />
                <Route path={routes.getNewAdminRoute.definition} element={<AdminNewPage />} />
                <Route path={routes.getAppConfigRoute.definition} element={<AppConfigPage />} />
                <Route
                  path={routes.getAiImageProvidersConfigRoute.definition}
                  element={<AiImageProvidersConfigPage />}
                />
                <Route
                  path={routes.getConversionPostbackConfigsRoute.definition}
                  element={<ConversionPostbackConfigsPage />}
                />
                <Route path={routes.getAdminActionLogsRoute.definition} element={<AdminActionLogsPage />} />
                <Route path={routes.getClearAdaptyRoute.definition} element={<ClearAdaptyPage />} />
                <Route path={routes.getClearUsersRoute.definition} element={<ClearUsersPage />} />
                <Route path={routes.getConversionPostbackLogsRoute.definition} element={<PosbackLogsListPage />} />
                <Route path={routes.getConversionsListRoute.definition} element={<ConversionsListPage />} />
                <Route
                  path={routes.getConversionsPredictionsRoute.definition}
                  element={<ConversionsPredictionsPage />}
                />
                <Route path={routes.getConversionsStatsRoute.definition} element={<ConversionsStatsPage />} />
                <Route path={routes.getConversionsStatsWebRoute.definition} element={<ConversionsStatsWebPage />} />
                <Route path={routes.getRetentionRoute.definition} element={<RetentionPage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </AppContextProvider>
      </TrpcProvider>
    </HelmetProvider>
  )
}
